<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-3">
                <b-card>
                    <h4 class="mb-4">Update information</h4>
                    
                    <b-button variant="primary" class="w-100" @click="tab = 1">
                        Personal Information
                    </b-button>
                    <b-button variant="primary" class="w-100 mt-3" @click="tab = 2">
                        Security
                    </b-button>
                    <b-button variant="primary" class="w-100 mt-3" @click="tab = 3">
                        KYC Verification
                    </b-button>
                </b-card>
            </div>
            <div class="col-12 col-sm-9">
                <Profile v-if="tab == 1"/>
                <Password v-if="tab == 2"/>
                <Kyc v-if="tab == 3"/>
            </div>
        </div>
    </div>
</template>
<script>
import Password from './Password.vue'
import Profile from './Profile.vue'
import Kyc from './Kyc.vue'
export default {
    components: {
        Password,
        Profile,
        Kyc
    },
    data () {
        return {
            tab: 1
        }
    }
}
</script>